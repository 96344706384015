import * as types from '@/store/types'
import request from '@/modules/request'
import GetCompany from '@/store/company/GetCompany'
import GetCompanyBalance from '@/store/company/GetCompanyBalance'
import rest from '@/modules/rest'
import moment from 'moment'
import { FEEDBACK } from '@/store/types'

const state = {
  company: {},
  permissions: []
}

const getters = {
  [types.COMPANY]: ({ company }) => company,
  [types.COMPANY_PERMISSIONS]: ({ company: { permissions } = {} }) => {
    return permissions || []
  },
  [types.COMPANY_TRIAL_CHECK]: (state) => {
    const start = moment.unix(state.company.trial_start_date)
    const expireData = start.add(process.env.TRIAL_TIME, 'days')
    const today = moment(new Date())
    const timestampDatesComparison = new Date(expireData * 1000).getTime() > new Date(today * 1000).getTime()
    const trialTimeCheck = state.company.trial && timestampDatesComparison
    return !state.company.trial || trialTimeCheck
  }
}

const mutations = {
  [types.COMPANY]: (state, payload) => {
    state.company = { ...payload }
  },

  [types.COMPANY_BALANCE]: (state, payload) => {
    state.company.balance = payload
  },

  [types.COMPANY_PERMISSIONS]: (state, payload) => {
    localStorage.setItem('permissions', payload)
    state.permissions = [...payload]
  }
}

const actions = {
  [types.COMPANY]: async ({ commit }) => {
    const { company } = await request(GetCompany)
    commit(types.COMPANY, company)
    commit(types.COMPANY_PERMISSIONS, company.permissions)
  },

  [types.COMPANY_BALANCE]: async ({ commit }) => {
    const { company } = await request(GetCompanyBalance)
    commit(types.COMPANY_BALANCE, company.balance)
  },

  [types.COMPANY_SETTINGS]: async ({ dispatch }, payload) => {
    try {
      await rest().patch('company/settings', payload)
      dispatch(FEEDBACK, {
        type: 'success',
        title: 'Configurações atualizadas',
        text: ''
      })
      dispatch(types.COMPANY)
      return 'success'
    } catch (err) {
      dispatch(FEEDBACK, {
        type: 'error',
        title: 'Ocorreu um erro atualizar os dados',
        text: ''
      })
      return 'error'
    }
  },
  [types.COMPANY_STYLE]: async ({ dispatch, commit }, payload) => {
    const formData = new FormData()
    if (payload.logo) {
      formData.append('logo', payload.logo)
    }

    Object.entries(payload)
      .filter(key => (typeof payload[key] !== 'undefined') && (typeof payload[key] !== 'object' || !!payload[key]))
      .forEach(key => formData.append(key, payload[key]))
    if (payload.colors) {
      formData.append('colors[primary]', payload.colors.primary)
      formData.append('colors[secondary]', payload.colors.secondary)
      formData.append('colors[tertiary]', payload.colors.tertiary)
    }
    try {
      const response = await rest().patch('/company/settings/style', formData)
      if (response && response.status === 204) {
        dispatch(FEEDBACK, {
          type: 'success',
          title: 'Configurações atualizadas',
          text: ''
        })
        dispatch(types.COMPANY)
        return 'success'
      } else {
        if (response.data) {
          const keys = Object.keys(response.data)
          keys.forEach(key => {
            dispatch(FEEDBACK, {
              type: 'error',
              title: `O campo ${response.data[key]} está incorreto`,
              text: response.data[key][0]
            })
          })
        }
      }
    } catch (e) {
      if (e.response.data && e.response.data.data) {
        const data = e.response.data.data
        const keys = Object.keys(e.response.data.data)
        keys.forEach(key => {
          dispatch(FEEDBACK, {
            type: 'error',
            title: `O campo ${key} está incorreto`,
            text: data[key][0]
          })
        })
      } else {
        dispatch(FEEDBACK, {
          type: 'error',
          title: 'Ocorreu um erro atualizar os dados',
          text: ''
        })
      }
      return 'error'
    }
  },

  [types.COMPANY_STYLE_LOGIN]: async ({ dispatch }, payload) => {
    const formData = new FormData()
    formData.append('intro', payload.intro)
    formData.append('developedByConvenia', payload.developedByConvenia ? 1 : 0)
    delete payload.signIn.developedByConvenia
    formData.append('title', payload.title)

    const fields = {
      background: payload.background,
      logo: payload.signIn?.signInLogo,
      favicon: payload.favicon
    }

    Object.entries(fields).forEach(([key, value]) => {
      if (value) {
        formData.append(key, value)
      }
    })
    Object.keys(payload.signIn).forEach((key) => {
      formData.append(`signIn[${key}]`, payload.signIn[key])
    })
    try {
      await rest().patch('/company/settings/style', formData)
      dispatch(FEEDBACK, {
        type: 'success',
        title: 'Configurações atualizadas',
        text: ''
      })
      dispatch(types.COMPANY)
      return 'success'
    } catch (err) {
      dispatch(FEEDBACK, {
        type: 'error',
        title: 'Ocorreu um erro atualizar os dados'
      })
      return 'error'
    }
  },

  [types.COMPANY_CALL_SALES]: async () => {
    try {
      await rest().post('lead/talk-to-sales')
    } catch (err) {
      return err
    }
  }
}

export default { state, getters, mutations, actions }
